import { SUPPORTS_DASH, SUPPORTS_HLS } from "./videoUtils";

import React, {
    useRef,
    useState,
    useEffect,
    forwardRef,
    useContext,
    useCallback,
    useMemo,
} from "react";

const ReactPlayer = require("react-player/file");

const WrapperPropsContext = React.createContext();

const VideoPlayer = forwardRef(
    (
        {
            url,
            hlsUrl,
            dashUrl,
            fileUrl,
            className,
            progressInterval,
            playsInline,
            autoPlay,
            preload,
            poster,
            controlsList,
            onPlay,
            onPause,
            onProgress,
            onLoadedMetadata,
            ...props
        },
        ref
    ) => {
        const [playing, setPlaying] = useState(autoPlay);
        const playerRef = useRef();

        const urlObj = typeof url === "string" ? { file: url } : url;

        useEffect(() => {
            if (ref) {
                ref.current = {
                    paused: playerRef?.current?.getInternalPlayer()?.paused,
                    duration: playerRef?.current?.getInternalPlayer()?.duration,
                    play: () => {
                        setPlaying(true);
                        return playerRef?.current?.getInternalPlayer()?.play();
                    },
                    pause: () => {
                        setPlaying(false);
                        return playerRef?.current?.getInternalPlayer()?.pause();
                    },
                    seekTo: (time) => {
                        const player = playerRef?.current?.getInternalPlayer();
                        if (player) {
                            player.currentTime = time;
                        }
                        // TODO: the following doesn't work. figure out why
                        // playerRef?.current?.seekTo(time, "seconds");
                    },
                };
            }
        }, [ref]);

        const progressHandler = useCallback(
            (progress) => {
                if (ref && ref.current) {
                    ref.current.currentTime = progress.playedSeconds;
                }
                if (onProgress) {
                    onProgress(progress);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [onProgress]
        );

        const metadataLoadedHandler = useCallback(
            (evt) => {
                if (ref && ref.current) {
                    ref.current.duration =
                        playerRef?.current?.getInternalPlayer()?.duration;
                }
                if (onLoadedMetadata) {
                    onLoadedMetadata(evt);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [onLoadedMetadata]
        );

        const playHandler = useCallback(
            (evt) => {
                if (ref && ref.current) {
                    ref.current.paused = false;
                }
                if (onPlay) {
                    onPlay(evt);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [onPlay]
        );

        const pauseHandler = useCallback(
            (evt) => {
                if (ref && ref.current) {
                    ref.current.paused = true;
                }
                if (onPause) {
                    onPause(evt);
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [onPause]
        );

        const providerProps = useMemo(() => ({ className }), [className]);

        return (
            <WrapperPropsContext.Provider value={providerProps}>
                <ReactPlayer
                    ref={playerRef}
                    url={selectVideoUrl({
                        hlsUrl: urlObj?.hls || hlsUrl,
                        dashUrl: urlObj?.dash || dashUrl,
                        fileUrl: urlObj?.file || fileUrl,
                    })}
                    wrapper={Wrapper}
                    playing={playing}
                    config={{
                        file: {
                            hlsVersion: "1.0.10",
                            dashVersion: "4.0.0-npm",
                            attributes: {
                                autoPlay,
                                preload,
                                poster,
                                controlsList,
                                disablePictureInPicture: true,
                                ...getRemotePlaybackAttribute(),
                            },
                        },
                    }}
                    playsinline={playsInline}
                    progressInterval={progressInterval}
                    onPlay={playHandler}
                    onPause={pauseHandler}
                    onProgress={progressHandler}
                    onLoadedMetadata={metadataLoadedHandler}
                    {...props}
                />
            </WrapperPropsContext.Provider>
        );
    }
);

VideoPlayer.displayName = "VideoPlayer";

VideoPlayer.defaultProps = {
    autoPlay: false,
    playsInline: true,
    progressInterval: 500,
};

export default VideoPlayer;

const Wrapper = forwardRef(({ children, ...props }, ref) => {
    const wrapperProps = useContext(WrapperPropsContext) || {};

    return (
        <div ref={ref} {...wrapperProps} {...props}>
            {children}
        </div>
    );
});

function selectVideoUrl({ hlsUrl, dashUrl, fileUrl }) {
    if (hlsUrl && SUPPORTS_HLS) {
        return hlsUrl;
    }
    if (dashUrl && SUPPORTS_DASH) {
        return dashUrl;
    }
    return fileUrl;
}

function getRemotePlaybackAttribute() {
    if (React.version.startsWith("16.")) {
        return { disableremoteplayback: "true" };
    }
    return { disableRemotePlayback: true };
}
